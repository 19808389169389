import React, { useEffect } from "react";

import styles from "./HomePage.module.css";
import mainStyles from "../Main.module.css";
import bikesImage from "../bikes.png";

import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import NewBikeList from "../NewBike/NewBikeList";
import UsedBikeList from "../UsedBike/UsedBikeList";
import UsedBike from "../UsedBike/UsedBike";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";

const UserHome = () => {
  const [showNewBike, setShowNewBike] = React.useState(false);
  const [showUsedBike, setShowUsedBike] = React.useState(false);
  const [showHome, setShowHome] = React.useState(true);
  const [showSellBike, setShowSellBike] = React.useState(false);
  const [modelId, setModelId] = React.useState("");
  const navigate = useNavigate();
  const jwt = localStorage.getItem("jwt");

  useEffect(() => {
    if (!jwt) {
      navigate("/login");
    }
  }, []);

  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
    ...theme.applyStyles("dark", {
      backgroundColor: "#1A2027",
    }),
  }));

  const handleIconClick = (id) => {
    setModelId(id);
  };

  const handleSellBikeClick = (e) => {
    navigate("/usedBike");
  };

  return (
    <div>
      {showHome && (
        <div>
          {" "}
          <div className={styles.root}>
            <div className={mainStyles.headingImageDiv}>
              <img
                src={bikesImage}
                alt="Bikes"
                className={mainStyles.bigImage}
              />
            </div>
            <div className={styles.buttonContainer1}>
              <div
                onClick={(e) => {
                  navigate("/newBikeList");
                }}
                className={styles.bikeButton}
              >
                <span>New&nbsp; Bike</span>
              </div>
              <div
                onClick={(e) => {
                  navigate("/usedBikeList");
                }}
                className={styles.bikeButton}
              >
                <span>Used Bike</span>
              </div>
            </div>
            <div className={styles.buttonContainer2}>
              <div
                onClick={(e) => {
                  navigate("/carWashBooking");
                }}
                className={styles.bikeButton}
              >
                <span>Car Wash</span>
              </div>
              <div
                onClick={(e) => {
                  navigate("/bikeServiceBooking");
                }}
                className={styles.bikeButton}
              >
                <span>Bike Service</span>
              </div>
            </div>
            <div className={styles.iconsGrid}>
              <Box sx={{ width: "100%" }}>
                <Grid
                  container
                  rowSpacing={1}
                  columnSpacing={{ xs: 1, sm: 2, md: 4 }}
                  sx={{
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Grid item xs={3.2}>
                    <Item>
                      <div
                        onClick={(e) =>
                          handleIconClick(
                            "brand-3c54c14e-b18d-47d8-a013-70c609ecb8d4"
                          )
                        }
                        className={styles.iconContainer}
                      >
                        <div className={styles.tvsIcon}></div>
                      </div>
                    </Item>
                  </Grid>
                  <Grid item xs={3.2}>
                    <Item>
                      <div
                        onClick={(e) =>
                          handleIconClick(
                            "brand-03590bb2-61d8-430a-816f-7081444e9474"
                          )
                        }
                        className={styles.iconContainer}
                      >
                        <div className={styles.bajajIcon}></div>
                      </div>
                    </Item>
                  </Grid>
                  <Grid item xs={3.2}>
                    <Item>
                      <div
                        onClick={(e) =>
                          handleIconClick(
                            "brand-5be9d22f-32ec-422f-b00d-e2aee5718a93"
                          )
                        }
                        className={styles.iconContainer}
                      >
                        <div className={styles.royalEnfieldIcon}></div>
                      </div>
                    </Item>
                  </Grid>
                  <Grid item xs={3.2}>
                    <Item>
                      <div
                        onClick={(e) =>
                          handleIconClick(
                            "brand-80857b32-c48e-494f-9bc6-6223595fccd9"
                          )
                        }
                        className={styles.iconContainer}
                      >
                        <div className={styles.yamahaIcon}></div>
                      </div>
                    </Item>
                  </Grid>
                  <Grid item xs={3.2}>
                    <Item>
                      <div
                        onClick={(e) =>
                          handleIconClick(
                            "brand-d4a13da6-f4d9-4429-b28e-be86f6dc950f"
                          )
                        }
                        className={styles.iconContainer}
                      >
                        <div className={styles.hondaIcon}></div>
                      </div>
                    </Item>
                  </Grid>
                  <Grid item xs={3.2}>
                    <Item>
                      <div
                        onClick={(e) =>
                          handleIconClick(
                            "brand-ea8a704d-6539-461a-8a89-7f36c1690dab"
                          )
                        }
                        className={styles.iconContainer}
                      >
                        <div className={styles.suzukiIcon}></div>
                      </div>
                    </Item>
                  </Grid>
                  <Grid item xs={3.2}>
                    <Item>
                      <div
                        onClick={(e) =>
                          handleIconClick(
                            "brand-7e859809-c158-4f09-b661-a3b83bf9d0f4"
                          )
                        }
                        className={styles.iconContainer}
                      >
                        <div className={styles.mahindraIcon}></div>
                      </div>
                    </Item>
                  </Grid>
                  <Grid item xs={3.2}>
                    <Item>
                      <div
                        onClick={(e) =>
                          handleIconClick(
                            "brand-b46b6c6c-08ed-4e76-8805-cd24f226a938"
                          )
                        }
                        className={styles.iconContainer}
                      >
                        <div className={styles.heroIcon}></div>
                      </div>
                    </Item>
                  </Grid>
                  <Grid item xs={3.2}>
                    <Item>
                      <div
                        onClick={(e) =>
                          handleIconClick(
                            "brand-cf8f7bb6-3d40-461b-a5be-451c47cadc41"
                          )
                        }
                        className={styles.iconContainer}
                      >
                        <div className={styles.ktmIcon}></div>
                      </div>
                    </Item>
                  </Grid>
                </Grid>
              </Box>
            </div>
          </div>
          <div className={styles.bottomButton}>
            <Button
              variant="contained"
              color="primary"
              size="large"
              onClick={(e) => {
                handleSellBikeClick(e);
              }}
            >
              Sell Bike
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

export default UserHome;
