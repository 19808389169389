import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import HomeIcon from "@mui/icons-material/Home";
import logo from "./images/blue-bike-logo2.png";
import styles from "./Header.module.css";
import { deepPurple } from "@mui/material/colors";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useEffect } from "react";
import { API_HOST_URL } from "../../config/apiConfig";
import { getUser, logout } from "../../redux/state/Auth/Action";
import { useDispatch, useSelector } from "react-redux";

const pages = [
  {
    id: "newBikeList",
    name: "Buy Bike",
  },
  {
    id: "usedBike",
    name: "Sell Bike",
  },
  {
    id: "usedBikeList",
    name: "Buy Used Bike",
  },
  {
    id: "bikeServiceBooking",
    name: "Bike Servicing",
  },
  {
    id: "carWashBooking",
    name: "Car Wash",
  },
  {
    id: "workshopList",
    name: "Workshops",
  },
  {
    id: "myBikes",
    name: "My Bikes",
  },
];

export function Header() {
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const [isBuyBikeSelected, setIsBuyBikeSelected] = React.useState(false);
  const [isBuyUsedBikeSelected, setIsBuyUsedBikeSelected] =
    React.useState(false);
  const [isSellBikeSelected, setIsSellBikeSelected] = React.useState(false);
  const [isBikeServiceSelected, setIsBikeServiceSelected] =
    React.useState(false);
  const [isCarWashSelected, setIsCarWashSelected] = React.useState(false);
  const [isCitySelected, setIsCitySelected] = React.useState(false);
  const [isAdminSelected, setIsAdminSelected] = React.useState(false);
  const [isBrandSelected, setIsBrandSelected] = React.useState(false);
  const [isBikeModelSelected, setIsBikeModelSelected] = React.useState(false);
  const [isCarModelSelected, setIsCarModelSelected] = React.useState(false);
  const [isNewBikeSelected, setIsNewBikeSelected] = React.useState(false);
  const [isOperatorSelected, setIsOperatorSelected] = React.useState(false);
  const [isWorkerSelected, setIsWorkerSelected] = React.useState(false);
  const [isWorkshopSelected, setIsWorkshopSelected] = React.useState(false);
  const [isCarWashBookingListSelected, setIsCarWashBookingListSelected] =
    React.useState(false);

  const [
    isBikeServiceBookingListSelected,
    setIsBikeServiceBookingListSelected,
  ] = React.useState(false);
  const [isCarWashStatusUpdateSelected, setIsCarWashStatusUpdateSelected] =
    React.useState(false);
  const [
    isBikeServicingStatusUpdateSelected,
    setIsBikeServicingStatusUpdateSelected,
  ] = React.useState(false);
  const [isWorkshopListSelected, setIsWorkshopListSelected] =
    React.useState(false);
  const [isStatusUpdate, setIsStatusUpdate] = React.useState(false);
  const [isReviewSelected, setIsReviewSelected] = React.useState(false);
  const [isMyBikesSelected, setIsMyBikesSelected] = React.useState(false);

  const navigate = useNavigate();
  const { auth } = useSelector((store) => store);

  const userName = localStorage.getItem("name");
  const userRole = localStorage.getItem("role");
  const userId = localStorage.getItem("userId");

  const token = localStorage.getItem("jwt");
  const carWash = localStorage.getItem("carWash");
  const bikeService = localStorage.getItem("bikeService");

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const jwt = localStorage.getItem("jwt");
  const dispatch = useDispatch();
  useEffect(() => {
    // if (userId === null || userId === "") {
    //   navigate("/");
    // }

    // if (carWash === "true") {
    //   setIsCarWashSelected(true);
    //   localStorage.setItem("carWash", false);
    // } else if (bikeService === "true") {
    //   setIsBikeServiceSelected(true);
    //   localStorage.setItem("bikeService", false);
    // }

    if (jwt) {
      dispatch(getUser(jwt));
    }
  }, [jwt]);

  // const handleLogout = (e) => {
  //   e.preventDefault();
  //   const data = {
  //     userId: userId,
  //   };

  //   const options = {
  //     headers: {
  //       "Content-Type": "application/json",
  //       Authorization: "Bearer " + token,
  //     },
  //   };

  //   axios
  //     .post(API_HOST_URL + "/auth/logout", data, options)
  //     .then((response) => {
  //       localStorage.clear();
  //       navigate("/login");
  //     })
  //     .catch((error) => {
  //       navigate("/login");
  //     });
  // };

  const handleLogout = (e) => {
    dispatch(logout(navigate));
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = (e) => {
    // alert(e.currentTarget.innerText);
    setPage(e.currentTarget.innerText);
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const setPage = (page) => {
    setAnchorElNav(null);
    navigate(page);
  };

  return (
    <div>
      <AppBar position="static" style={{ backgroundColor: "skyblue" }}>
        <Container maxWidth="xl">
          <Toolbar disableGutters>
            <div>
              <a href="/home">
                {" "}
                <img
                  src={logo}
                  className="logo_img"
                  alt="logo"
                  style={{
                    width: "100px",
                    height: "40px",
                    verticalAlign: "bottom",
                  }}
                />
              </a>
            </div>
            <a href="/home">
              <HomeIcon sx={{ display: { xs: "flex", md: "none" }, mr: 1 }} />
            </a>
            <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="inherit"
              >
                <MenuIcon />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                  display: { xs: "block", md: "none" },
                }}
              >
                {pages.map((page) => (
                  <MenuItem key={page.id} onClick={setPage.bind(this, page.id)}>
                    <Typography textAlign="center">{page.name}</Typography>
                  </MenuItem>
                ))}
              </Menu>
            </Box>

            <Typography
              variant="h5"
              noWrap
              component="a"
              href="#app-bar-with-responsive-menu"
              sx={{
                mr: 2,
                display: { xs: "flex", md: "none" },
                flexGrow: 1,
                fontFamily: "monospace",
                fontWeight: 700,
                letterSpacing: ".3rem",
                color: "inherit",
                textDecoration: "none",
              }}
            ></Typography>
            <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
              {pages.map((page) => (
                <Button
                  key={page.id}
                  onClick={setPage.bind(this, page.id)}
                  sx={{ my: 2, color: "white", display: "block" }}
                >
                  {page.name}
                </Button>
              ))}
            </Box>

            <div>
              {(userRole === "ADMIN" || userRole === "OPERATOR") && (
                <Button
                  id="basic-button"
                  aria-controls={open ? "basic-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? "true" : undefined}
                  onClick={handleClick}
                  sx={{ color: "white" }}
                >
                  Reg
                </Button>
              )}
            </div>
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              {userRole === "ADMIN" && (
                <MenuItem key="Brand" onClick={setPage.bind(this, "Brand")}>
                  <Typography textAlign="center">Brand</Typography>
                </MenuItem>
              )}
              {userRole === "ADMIN" && (
                <MenuItem
                  key="Bike Model"
                  onClick={setPage.bind(this, "bikeModel")}
                >
                  <Typography textAlign="center">Bike Model</Typography>
                </MenuItem>
              )}
              {userRole === "ADMIN" && (
                <MenuItem
                  key="Car Model"
                  onClick={setPage.bind(this, "carModel")}
                >
                  <Typography textAlign="center">Car Model</Typography>
                </MenuItem>
              )}
              {userRole === "ADMIN" && (
                <MenuItem
                  key="Operator"
                  onClick={setPage.bind(this, "operator")}
                >
                  <Typography textAlign="center">Operator</Typography>
                </MenuItem>
              )}
              {(userRole === "ADMIN" || userRole === "OPERATOR") && (
                <MenuItem key="Worker" onClick={setPage.bind(this, "worker")}>
                  <Typography textAlign="center">Worker</Typography>
                </MenuItem>
              )}
              {(userRole === "ADMIN" || userRole === "OPERATOR") && (
                <MenuItem
                  key="Workshop"
                  onClick={setPage.bind(this, "workshop")}
                >
                  <Typography textAlign="center">Workshop</Typography>
                </MenuItem>
              )}
              {userRole === "ADMIN" && (
                <MenuItem key="Admin" onClick={setPage.bind(this, "Admin")}>
                  <Typography textAlign="center">Admin</Typography>
                </MenuItem>
              )}
              {(userRole === "ADMIN" || userRole === "OPERATOR") && (
                <MenuItem
                  key="New Bike"
                  onClick={setPage.bind(this, "newBike")}
                >
                  <Typography textAlign="center">New Bike</Typography>
                </MenuItem>
              )}
              {userRole === "ADMIN" && (
                <MenuItem key="City" onClick={setPage.bind(this, "city")}>
                  <Typography textAlign="center">City</Typography>
                </MenuItem>
              )}
              {/* {(userRole === "ADMIN" || userRole === "OPERATOR") && (
                <MenuItem
                  key="Car Wash Booking List"
                  onClick={setPage.bind(this, "carWashBookingList")}
                >
                  <Typography textAlign="center">
                    Car Wash Booking List
                  </Typography>
                </MenuItem>
              )}
              {(userRole === "ADMIN" || userRole === "OPERATOR") && (
                <MenuItem
                  key="Bike Service Booking List"
                  onClick={setPage.bind(this, "bikeServiceBookingList")}
                >
                  <Typography textAlign="center">
                    Bike Service Booking List
                  </Typography>
                </MenuItem>
              )} */}
              {/* {(userRole === "ADMIN" || userRole === "OPERATOR") && (
                <MenuItem
                  key="Car Wash Status Update"
                  onClick={setPage.bind(this, "carWashstatusUpdate")}
                >
                  <Typography textAlign="center">
                    Car Wash Status Update
                  </Typography>
                </MenuItem>
              )}
              {(userRole === "ADMIN" || userRole === "OPERATOR") && (
                <MenuItem
                  key="Bike Servicing Status Update"
                  onClick={setPage.bind(this, "bikeServicingStatusUpdate")}
                >
                  <Typography textAlign="center">
                    Bike Servicing Status Update
                  </Typography>
                </MenuItem>
              )} */}
              {(userRole === "ADMIN" || userRole === "OPERATOR") && (
                <MenuItem
                  key="Status Update"
                  onClick={setPage.bind(this, "statusUpdate")}
                >
                  <Typography textAlign="center">Status Update</Typography>
                </MenuItem>
              )}
              {userRole === "ADMIN" && (
                <MenuItem
                  key="Review"
                  onClick={setPage.bind(this, "reviewActions")}
                >
                  <Typography textAlign="center">Review Actions</Typography>
                </MenuItem>
              )}
            </Menu>

            <Box sx={{ flexGrow: 0 }}>
              <Tooltip title="Open settings">
                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                  <Avatar sx={{ bgcolor: deepPurple[500] }}>
                    {auth.user.name?.charAt(0)}
                  </Avatar>
                </IconButton>
              </Tooltip>
              <Menu
                sx={{ mt: "45px" }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
              >
                <MenuItem key="Profile" onClick={handleCloseUserMenu}>
                  <Typography
                    textAlign="center"
                    onClick={setPage.bind(this, "profile")}
                  >
                    Profile
                  </Typography>
                </MenuItem>
                <MenuItem key="Logout" onClick={handleLogout}>
                  <Typography textAlign="center">Logout</Typography>
                </MenuItem>
              </Menu>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
    </div>
  );
}
