import { useState, useEffect } from "react";
import CustomSlider from "../CustomSlider/custom.slider";
import { styled } from "@mui/system";
import { Tabs } from "@mui/base/Tabs";
import { TabsList as BaseTabsList } from "@mui/base/TabsList";
import { TabPanel as BaseTabPanel } from "@mui/base/TabPanel";
import { buttonClasses } from "@mui/base/Button";
import { Tab as BaseTab, tabClasses } from "@mui/base/Tab";
import styles from "./UsedBike.module.css";
import { API_HOST_URL } from "../Constant/Constant";
import { HeaderPlain } from "../Header/HeaderPlain";
import mainStyles from "../Main.module.css";

import React from "react";
import { Footer } from "../Footer/Footer";
import { api } from "../../config/apiConfig";
import { useLocation } from "react-router-dom";
import MainCarousel from "../MainCarousel/MainCarousel";

const blue = {
  50: "#F0F7FF",
  100: "#C2E0FF",
  200: "#80BFFF",
  300: "#66B2FF",
  400: "#3399FF",
  500: "#007FFF",
  600: "#0072E5",
  700: "#0059B2",
  800: "#004C99",
  900: "#003A75",
};

const grey = {
  50: "#F3F6F9",
  100: "#E5EAF2",
  200: "#DAE2ED",
  300: "#C7D0DD",
  400: "#B0B8C4",
  500: "#9DA8B7",
  600: "#6B7A90",
  700: "#434D5B",
  800: "#303740",
  900: "#1C2025",
};

const Tab = styled(BaseTab)`
  font-family: "IBM Plex Sans", sans-serif;
  color: #fff;
  cursor: pointer;
  font-size: 0.875rem;
  font-weight: 600;
  background-color: transparent;
  width: 100%;
  padding: 10px 12px;
  margin: 6px;
  border: none;
  border-radius: 7px;
  display: flex;
  justify-content: center;

  &:hover {
    background-color: ${blue[400]};
  }

  &:focus {
    color: #fff;
    outline: 3px solid ${blue[200]};
  }

  &.${tabClasses.selected} {
    background-color: #fff;
    color: ${blue[600]};
  }

  &.${buttonClasses.disabled} {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

const TabPanel = styled(BaseTabPanel)(
  ({ theme }) => `
    width: 95%;
    font-family: 'IBM Plex Sans', sans-serif;
    font-size: 0.875rem;
    padding: 20px 12px;
    background: ${theme.palette.mode === "dark" ? grey[900] : "#fff"};
    border: 1px solid ${theme.palette.mode === "dark" ? grey[700] : grey[200]};
    border-radius: 12px;
    opacity: 0.6;
    `
);

const TabsList = styled(BaseTabsList)(
  ({ theme }) => `
    min-width: 375px;
    background-color: ${blue[500]};
    border-radius: 12px;
    margin-bottom: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    align-content: space-between;
    box-shadow: 0px 4px 30px ${
      theme.palette.mode === "dark" ? grey[900] : grey[200]
    };
    `
);
export default function UsedBikeDetail() {
  const [images, setImages] = useState([]);
  const [bike, setBike] = useState([]);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const location = useLocation();
  const bikeId = new URLSearchParams(location.search).get("bikeId");

  useEffect(() => {
    if (localStorage.getItem("token")) {
      setIsLoggedIn(true);
    }

    api.get("/api/usedBikes/" + bikeId).then((res) => {
      setBike(res.data);
      setImages(res.data.images);
    });
  }, [bikeId]);

  return (
    <div>
      {bike && (
        <header className={mainStyles.header}>
          {bike.brand} {bike.model}
        </header>
      )}

      <div className="slider">
        {bike && <MainCarousel imageList={images} />}
        <div className={mainStyles.headerPrice}>₹{bike.price} /-</div>
        <div className="bikeDetail">
          {bike && (
            <Tabs defaultValue={0}>
              <TabsList>
                <Tab value={0}>Bike details</Tab>
                <Tab value={1}>Seller Details</Tab>
              </TabsList>

              <TabPanel value={0}>
                <div className="leftDiv">
                  <div className={mainStyles.fieldContianer}>
                    <div className={styles.fieldName}>Brand </div>
                    <div className={styles.fieldValue}>{bike.brand}</div>
                  </div>
                  <div className={mainStyles.fieldContianer}>
                    <div className={styles.fieldName}>Model </div>
                    <div className={styles.fieldValue}> {bike.model}</div>
                  </div>
                  <div className={mainStyles.fieldContianer}>
                    <div className={styles.fieldName}>Color </div>
                    <div className={styles.fieldValue}>{bike.color}</div>
                  </div>
                  <div className={mainStyles.fieldContianer}>
                    <div className={styles.fieldName}>Price </div>
                    <div className={styles.fieldValue}>{bike.price}</div>
                  </div>
                </div>
                <div className="rightDiv">
                  <div className={mainStyles.fieldContianer}>
                    <div className={styles.fieldName}>Purchase Year </div>
                    <div className={styles.fieldValue}>{bike.purchaseYear}</div>
                  </div>
                  <div className={mainStyles.fieldContianer}>
                    <div className={styles.fieldName}>Model Year </div>
                    <div className={styles.fieldValue}>{bike.modelYear}</div>
                  </div>
                  <div className={mainStyles.fieldContianer}>
                    <div className={styles.fieldName}>Description </div>
                    <div className={styles.fieldValue}>{bike.description}</div>
                  </div>
                  <div className={mainStyles.fieldContianer}>
                    <div className={styles.fieldName}>Registration # </div>
                    <div className={styles.fieldValue}>
                      {bike.registrationNumber}
                    </div>
                  </div>
                </div>
              </TabPanel>
              <TabPanel value={1}>
                <div>
                  {" "}
                  <div className={mainStyles.fieldContianer}>
                    <div className={styles.fieldName}>Seller </div>
                    <div className={styles.fieldValue}>{bike.sellerName}</div>
                  </div>
                  <div className={mainStyles.fieldContianer}>
                    <div className={styles.fieldName}>Contact </div>
                    <div className={styles.fieldValue}>
                      {" "}
                      {bike.sellerMobile}
                    </div>
                  </div>
                  <div className={mainStyles.fieldContianer}>
                    <div className={styles.fieldName}>Address </div>
                    <div className={styles.fieldValue}>
                      {bike.sellerAddress}
                    </div>
                  </div>
                  <div className={mainStyles.fieldContianer}>
                    <div className={styles.fieldName}>City </div>
                    <div className={styles.fieldValue}>{bike.sellerCity}</div>
                  </div>
                </div>
              </TabPanel>
            </Tabs>
          )}
        </div>
      </div>
      <Footer />
    </div>
  );
}
