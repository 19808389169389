import styles from "./Footer.module.css";

export function Footer() {
  return (
    <div>
      <div className={styles.footer}>
        <div>All rights reserved &copy; bluebike.in</div>
        <div>
          <a href="/contact">&nbsp; Contact &nbsp; </a>
        </div>
        <div>
          <a href="/aboutUs">&nbsp; About </a>
        </div>
      </div>
    </div>
  );
}
