import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./LoginPage.module.css";
import mainStyles from "../Main.module.css";
import axios from "axios";
import bigImage from "./images/main-bike.png";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { API_HOST_URL } from "../../config/apiConfig";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { useDispatch } from "react-redux";
import { login } from "../../redux/state/Auth/Action";

export default function LoginPage() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [mailError, setMailError] = useState(false);
  const [linkSent, setLinkSent] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const dispatch = useDispatch();
  const userId = localStorage.getItem("userId");
  const handleLogin = (e) => {
    e.preventDefault();
    const userData = {
      email: email,
      password: password,
    };
    const request = { userData, navigate };
    dispatch(login(request));
  };

  useEffect(() => {
    if (userId !== null) {
      navigate("/home");
    }
  });

  const handleSend = () => {
    axios
      .post(API_HOST_URL + "/auth/sendResetPasswordLink", { email: email })
      .then((response) => {
        if (response.data.success === true) {
          setLinkSent(true);
          setSuccessMessage(response.data.message);
        } else {
          setMailError(true);
        }
      })
      .catch((error) => {
        setMailError(true);
      });
  };

  const handleClose = () => {
    setOpen(false);
    setMailError(false);
    setLinkSent(false);
  };

  const handleOpen = (e) => {
    setMailError(false);
    setLinkSent(false);
    setOpen(true);
  };

  return (
    <div className={styles.container}>
      <div className={styles.heading}>
        <div className={styles.heading1}>Chase Your Dream with</div>
        <div className={styles.heading2}>Blue Bike</div>
      </div>
      <div className={styles.headingImageDiv}>
        <img src={bigImage} alt="Bike" className={styles.bigImage} />
      </div>

      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          component: "form",
          onSubmit: (event) => {
            event.preventDefault();
            const formData = new FormData(event.currentTarget);
            const formJson = Object.fromEntries(formData.entries());
            const email = formJson.email;
            console.log(email);
            handleClose();
          },
        }}
      >
        <DialogTitle>Forgot Password</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Plese enter the registered email to reset password.
          </DialogContentText>
          <TextField
            autoFocus
            required
            type="email"
            margin="dense"
            id="email"
            name="email"
            label="email"
            fullWidth
            variant="standard"
            onChange={(e) => setEmail(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleSend}>Send</Button>
          <Button onClick={handleClose}>Cancel</Button>
        </DialogActions>
        {mailError && (
          <div className={mainStyles.errorMessage}>
            Please enter a registered email id.
          </div>
        )}
        {linkSent && (
          <div className={mainStyles.successMessage}>{successMessage}</div>
        )}
      </Dialog>

      <div className={styles.loginPageContainer}>
        <div className={styles.loginPage}>
          <div className={styles.loginContainer}>
            <h1>Login to Blue Bike</h1>
            <form onSubmit={handleLogin} className={styles.loginForm}>
              <label htmlFor="email">Email:</label>
              <input
                type="email"
                id="email"
                name="username"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />

              <label htmlFor="password">Password:</label>
              <div className={mainStyles.passwordDiv}>
                <input
                  type={showPassword ? "text" : "password"}
                  id="password"
                  name="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                />
                <div className={mainStyles.showPasswordIcon}>
                  {showPassword ? (
                    <RemoveRedEyeIcon onClick={() => setShowPassword(false)} />
                  ) : (
                    <VisibilityOffIcon onClick={() => setShowPassword(true)} />
                  )}
                </div>
              </div>
              <button type="submit">Login</button>
              <div className={styles.linksContainer}>
                <div className={styles.loginLinks}>
                  <div onClick={(e) => handleOpen(e)}>Forgot Password</div>
                  <div>
                    <a href="/customer">Register</a>
                  </div>
                </div>
                {error && (
                  <p className={styles.error}>
                    {" "}
                    Please enter valid email/password.{" "}
                  </p>
                )}
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
